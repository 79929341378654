@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .sidenav {
    background: map.get($bg, 'card');
  }

  .sidenav-menu-item {
    &, &:visited {
      color: map.get($fg, 'secondary');
    }

    &:hover, &:active {
      color: mat.get-color-from-palette($accent-palette);
    }

    &.active {
      color: map.get($fg, 'accent');
    }
  }
}

@mixin typography($theme, $fg, $bg) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }
}
