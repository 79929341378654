@use '@angular/material' as mat;
@use '../mixins' as mixins;
@use 'sass:map';
@use 'sass:math';

$menu-border-radius: 8px !default;
$menu-indicator-width: 4px !default;
$menu-indicator-offset: 7px !default;
$menu-icon-offset: 8px !default;
$menu-icon-size: 16px !default;

@mixin core() {
  .app-menu {
    border-radius: $menu-border-radius;
    @include mixins.elevation();

    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  .app-menu-item {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;

    &::before {
      content: '';
      position: absolute;
      width: $menu-indicator-width;
      border-radius: $menu-indicator-width;
      left: - math.div($menu-indicator-width, 2);
      top: $menu-indicator-offset;
      bottom: $menu-indicator-offset;
      opacity: 0;
    }

    &:hover::before {
      opacity: 1;
    }

    [class^='icon-'], [class*=' icon-'], .mat-icon {
      font-size: $menu-icon-size;
      margin-right: $menu-icon-offset;
    }

    .mat-icon {
      width: $menu-icon-size;
      height: $menu-icon-size;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  $accent-palette: map.get(mat.get-color-config($theme), 'accent');
  $accent: mat.get-color-from-palette($accent-palette);

  .app-menu-item {
    &:not([disabled]) {
      --mat-option-selected-state-label-text-color: #{$accent};
      color: map.get($fg, 'subdued');
      &:hover {
        color: $accent;
        background-color: map.get($bg, 'dark');
      }
    }

    &::before {
      background: $accent;
    }

    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
      background-color: map.get($bg, 'light');
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.get-typography-config($theme);

  .app-menu-item {
    &.mat-mdc-menu-item {
      font-size: mat.font-size($typography-config, 'subtitle-2');
      font-weight: mat.font-weight($typography-config, 'subtitle-2');
      line-height: mat.line-height($typography-config, 'subtitle-2');
      font-family: mat.font-family($typography-config, 'subtitle-2');

      letter-spacing: mat.letter-spacing($typography-config, 'subtitle-2');
      --mat-menu-item-label-text-font: #{mat.font-family($typography-config, 'subtitle-2')};
      --mat-menu-item-label-text-line-height: #{mat.line-height($typography-config, 'subtitle-2')};
      --mat-menu-item-label-text-size: #{mat.font-size($typography-config, 'subtitle-2')};
      --mat-menu-item-label-text-tracking: #{mat.letter-spacing($typography-config, 'subtitle-2')};
      --mat-menu-item-label-text-weight: #{mat.font-weight($typography-config, 'subtitle-2')};
    }
    &.mat-mdc-option {
      @include mat.typography-level($typography-config, 'body-2')
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
