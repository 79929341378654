@use '@angular/material' as mat;
@use 'sass:map';

@mixin core() {
  .chart-tooltip-overlay {
    pointer-events: none;
  }
}

@mixin color($theme, $fg, $bg) {
  .chart-tooltip-overlay {
    .tooltip{
      background: map.get($bg, tooltip);
    }

    .tooltip-title {
      color: map.get($fg, contrast);
      background: map.get($bg, tooltip-title);
    }

    .item-name {
      color: map.get($fg, contrast);
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.get-typography-config($theme);

  .tooltip-title {
    font-size: 16px;
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
