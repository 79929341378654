@use '@angular/material' as mat;
@use '../mixins' as mixins;
@use 'sass:map';

$dialog-border-radius: 20px;
$dialog-padding: 30px 60px;
$dialog-min-width: 450px;
$dialog-actions-gap: 20px;

@mixin core() {
  .cdk-dialog-overlay {
    @include mixins.overlay()
  }

  .app-dialog {
    .mat-mdc-dialog-surface {
      --mdc-dialog-container-shape: #{$dialog-border-radius};
    }

    .mat-mdc-dialog-title {
      text-align: center;
    }

    .mat-mdc-dialog-container {
      position: relative;
      min-width: $dialog-min-width;
    }

    .mat-mdc-dialog-actions {
      justify-content: center;
      padding: 16px 8px 32px 8px;

      .mat-mdc-button-base {
        padding: 0;

        + .mat-mdc-button-base {
          margin-left: $dialog-actions-gap;
        }
      }
    }
  }

  .app-dialog-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 10px;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-wrapper {
      display: flex;
    }

    i {
      font-size: 16px;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);

  .app-dialog-close {
    color: map.get($fg, 'secondary')
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
