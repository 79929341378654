@use '@angular/material' as mat;
@use 'sass:map';
@use '../mixins' as mixins;

$table-border-radius: 8px;

@mixin core() {
  .app-table {
    width: 100%;
    border-radius: $table-border-radius;
    @include mixins.elevation();

    th, td {
      border: 0
    }

    th:first-of-type {
      border-top-left-radius: $table-border-radius;
    }

    th:last-of-type {
      border-top-right-radius: $table-border-radius;
    }

    tr:last-of-type {
      td:first-of-type {
        border-bottom-left-radius: $table-border-radius;
      }

      td:last-of-type {
        border-bottom-right-radius: $table-border-radius;
      }
    }
  }
}

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  $accent: map.get($color-config, 'accent');

  .app-table {
    th {
      color: map.get($fg, 'accent')
    }

    .mat-sort-header-arrow {
      color: mat.get-color-from-palette($accent);
    }

    tbody tr:nth-child(odd) {
      background-color: map.get($bg, 'light');
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  .app-table {
    th {
      @include mat.typography-level($typography-config, 'subtitle-2');
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
