@use '@angular/material' as mat;
@use 'sass:map';

@mixin core() {
  app-pie {
    position: absolute;
    inset: 0;

    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
    }

    polyline {
      stroke-width: 1px;
      fill: none;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  app-pie {
    .lines {
      stroke: map.get($fg, secondary);
    }

    .circle {
      fill: map.get($fg, secondary)
    }
  }
}

@mixin typography($theme, $fb, $bg) {
  $typography-config: mat.get-typography-config($theme);

  app-pie {
    text {
      .name {
        font-size: 12px;
        font-weight: 700;
        text-transform: capitalize;
      }

      .value {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

@mixin theme($theme, $fb, $bg) {
  @include core();

  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fb, $bg);
  }
}
