@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);

  $defaultFG: map.get($fg, 'default');
  $lightBackground: map.get($bg, 'light');
  $secondaryText: map.get($fg, 'subdued');
  $divider: map.get($fg, 'border-stroke');

  .negative {
    color: map.get(mat.$red-palette, 900);
  }

  .positive {
    color: map.get(mat.$green-palette, 900);
  }

  .title-box{
    background: $lightBackground;
  }
  .app-table th{
    color: $defaultFG;
  }
}

@mixin typography($theme, $fg, $bg) {
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }
}
