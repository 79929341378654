@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  .auth {
    background: linear-gradient(
        to top,
        mat.get-color-from-palette($accent-palette, 700) 0%,
        mat.get-color-from-palette($accent-palette, 200) 50%,
        transparent 50%
    );
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
