@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .page-content-collapse-button {
    color: map.get($fg, 'accent');
  }

  .page-content-description {
    color: map.get($fg, 'tertiary')
  }

  .page-content-bot {
    color: map.get($fg, 'secondary')
  }
}

@mixin typography($theme, $fg, $bg) {
  $typography-config: mat.get-typography-config($theme);

  .page-content-name {
    @include mat.typography-level($typography-config, 'headline-5');
    line-height: 0.8;
  }

  .page-content-collapse-button {
    font-size: 24px;
  }

  .page-content-bot {
    @include mat.typography-level($typography-config, 'caption');
  }
}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);

  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }

}
