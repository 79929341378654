@use 'sass:map';
@use '@angular/material' as mat;
@use 'sass:math';

$scrollbar-size: 10px;

@mixin core() {
  * {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    overflow: hidden;
  }

  body {
    margin: 0;
  }

  label {
    cursor: inherit;
  }

  button {
    font: inherit;
    text-align: left;
  }

  ::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $scrollbar-size;
  }

  a, a:hover, a:active, a:visited {
    text-decoration: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
}

@mixin color($theme, $fg, $bg) {
  $accent-palette: map.get(mat.get-color-config($theme), 'accent');
  $accent: mat.get-color-from-palette($accent-palette);

  background: map.get($bg, 'dark');
  color: map.get($fg, 'default');

  label {
    color: map.get($fg, 'subdued')
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(map.get($fg, 'border-stroke'), 1);
  }

  a, a:hover, a:active, a:visited {
    color: $accent;
  }

  @each $name, $value in $bg {
    .bg-#{$name} {
      background-color: $value;
    }
  }

  @each $name, $value in $fg {
    .text-#{$name} {
      color: $value;
    }
  }
}

@mixin typography($theme) {
  $typography-config: mat.get-typography-config($theme);

  a {
    @include mat.typography-level($typography-config, 'subtitle-2');
  }
}

@mixin theme($theme, $fb, $bg) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fb, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
