@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);

  $cardBackground: map.get($bg, 'card');

  app-funnel{
    .no-data{
      background-color: $cardBackground;
    }
    .no-data {
      color: map.get($fg, secondary);
    }
  }

}

@mixin typography($theme, $fg, $bg) {

}

@mixin theme($theme, $fg, $bg) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme, $fg, $bg);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme, $fg, $bg);
  }
}
